body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

body {
  background-color: #f1f1f1;
}

.recharts-wrapper-old {
  background: #ffffff;
  border-radius: 3px;
  border: #d9d9d9 1px solid;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingdiv {
  opacity: 40%;
  pointer-events: none;
}

.statsSection {
  display: flex;
  justify-content: center;
}


.statsTile {
  background-color: #ffffff;
  border-radius: 5px;
  border: #bfbfbf 1px solid;
  padding: 8px;
  margin: 10px;
  color: #6e6e6e;
  width: 120px;
}

.statsTile h1 {
  font-size: 2em;
}
.statsTile p {
  font-size: 0.9em;
}

.centerPanel {
  margin-left: auto;
  margin-right: auto;
  max-width: 450px;
}
.loginWindow {
  padding: 20px;
}

.loginWindow h1 {
  margin-top: 0px;
}

.loginWindow h2 {
  font-size: 1.2em;
  font-weight: 300;
}

.errorBox {
  color: #cd3d64;
  text-align: left;
  font-weight: 500;
}